import { useInfiniteQuery } from '@tanstack/react-query';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import { ContentCategory, ContentSurface } from ':dapp/pages/ocs/types/ocsResponseTypes';

import { getContentByTrendingUnauth } from './useOCSTrending';

export function useOCSOnchainExperiences(
  category?: ContentCategory,
  handleError?: (err?: Error) => void,
) {
  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  return useInfiniteQuery({
    queryKey: ['useOCSOnchainExperiences', category, account?.primaryAddressForDapp],
    queryFn: async ({ pageParam = '' }) =>
      getContentByTrendingUnauth({
        surface: ContentSurface.SURFACE_OCS,
        walletAddress: account?.primaryAddressForDapp,
        pageOptions: {
          size: 30,
          cursor: pageParam || undefined,
        },
        categories: category ? [category] : undefined,
      }),
    getNextPageParam: (lastPage) => {
      const nextPage = lastPage.pageDescription.nextCursor;
      return nextPage || false;
    },
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
    staleTime: 1000 * 35,
    suspense: false,
  });
}
