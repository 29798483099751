import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import { useOCSTabs } from ':dapp/pages/ocs/hooks/useOCSTabs';
import { RoutesEnum } from ':dapp/utils/RoutesEnum';

export function useActiveOCSTab() {
  const router = useRouter();
  const ocsTabs = useOCSTabs();

  const handleTabChange = useCallback(
    (tab: string) => {
      // todo: log tab clicked if required, check example 'logAssetTabClicked' from 'cb-wallet-analytics/assets'

      router.push(`${RoutesEnum.OCS}/${tab}`, undefined, { scroll: false });
    },
    [router],
  );

  return useMemo(() => {
    const tabType = router.pathname.split('/').filter(Boolean)[1];

    return {
      activeTab: tabType || ocsTabs[0]?.id,
      onTabChange: handleTabChange,
    };
  }, [handleTabChange, router, ocsTabs]);
}
