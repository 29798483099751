import { ErrorBoundary } from 'wallet-cds-web/components/ErrorBoundary';
import { GenericErrorPage } from 'wallet-cds-web/components/GenericErrorPage';
import { ResponsiveProps } from '@cbhq/cds-common';
import { zIndex } from '@cbhq/cds-common/tokens/zIndex';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { HStack, VStack } from '@cbhq/cds-web/layout';
import { paletteValueToCssVar } from '@cbhq/cds-web/utils/palette';
import { Footer } from ':dapp/components/Footer/Footer';
import { OCSHeader } from ':dapp/pages/ocs/components/OCSHeader';
import { OCSTabs } from ':dapp/pages/ocs/components/OCSTabs';
import { NuxWrapper } from './OCSNux/NuxWrapper';
import { OCSBanner } from './OCSBanner';
const responsiveWrapperSpacing: ResponsiveProps = {
  phone: {
    gap: 1
  },
  tablet: {
    gap: 3
  },
  desktop: {
    gap: 3
  }
};
const responsiveViewSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3
  },
  tablet: {
    spacingHorizontal: 4
  },
  desktop: {
    spacingHorizontal: 4
  }
};
const responsiveTabSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3
  },
  tablet: {
    spacingHorizontal: 4
  },
  desktop: {
    spacingHorizontal: 4
  }
};
type AssetsPageWrapperProps = {
  children: React.ReactNode;
};
const vstackStyles = "v1qlwbsq";
export function OCSPageWrapper({
  children
}: AssetsPageWrapperProps) {
  return <ResponsiveWrapper>
      <NuxWrapper>
        <VStack responsiveConfig={responsiveWrapperSpacing} className={vstackStyles}>
          <OCSBanner />
          <OCSHeader />

          <HStack justifyContent="space-between" alignItems="center" position="sticky" top="80px" zIndex={zIndex.navigation} responsiveConfig={responsiveTabSpacing} dangerouslySetBackground={paletteValueToCssVar('gray0')} testID="ocs-tabs-container">
            <OCSTabs />
          </HStack>
          <ErrorBoundary fallback={<GenericErrorPage />} context="dapp_error">
            <VStack spacingBottom={2} responsiveConfig={responsiveViewSpacing}>
              {children}

              <Footer />
            </VStack>
          </ErrorBoundary>
        </VStack>
      </NuxWrapper>
    </ResponsiveWrapper>;
}
function ResponsiveWrapper({
  children
}: AssetsPageWrapperProps) {
  const {
    isDesktop
  } = useBreakpoints();
  if (!isDesktop) {
    return <>{children}</>;
  }
  return <VStack width="100%" alignItems="center">
      {children}
    </VStack>;
}

require("./OCSPageWrapper.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSPageWrapper.tsx");