import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';

import { GetFeaturedContentUnauthResponse } from '../types/ocsResponseTypes';

export function useOCSFeatured(handleError?: (err?: Error) => void) {
  const { account, accountsWithBalances } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  const result = useQuery({
    queryKey: ['getContentByFeaturedUnauth', account],
    queryFn: async () => {
      return getJSON<GetFeaturedContentUnauthResponse>('explore/getContentByFeaturedUnauth', {
        surface: '1',
        walletAddress: account?.primaryAddressForDapp ?? '',
      });
    },
    // this actually helps us to avoid calling the endpoint if there are connected wallets but account is yet to be loaded
    enabled: Boolean(
      accountsWithBalances.length === 0 || (accountsWithBalances.length > 0 && !!account),
    ),
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
    staleTime: 1000 * 30,
    suspense: false,
  });

  // enabled option isn't working as expected.
  // this condition skips calling featured endpoint is account is not to be loaded
  if (accountsWithBalances.length > 0 && !account) {
    return {
      data: null,
      isLoading: true,
    };
  }

  return result;
}
