import Image from 'next/image';
import { ResponsiveProps } from '@cbhq/cds-common';
import { Box, HStack } from '@cbhq/cds-web/layout';
import { useIsMobile } from ':dapp/hooks/useIsMobile';
export const testID = 'ocs-banner';
const responsiveHeaderSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3,
    spacingTop: 2
  },
  tablet: {
    spacingHorizontal: 4,
    spacingTop: 2
  },
  desktop: {
    spacingHorizontal: 4,
    spacingTop: 2
  }
};
const imageClass = "iieuu1u";
export function OCSBanner() {
  const isMobile = useIsMobile();

  // `blurDataURL` needs to be a local URL for it to display (instead of black) during gif loading (tested on 4g speed)
  return <HStack width="100%" height="auto" justifyContent="space-between" alignItems="center" responsiveConfig={responsiveHeaderSpacing} testID={testID}>
      <Box width="100%" height={isMobile ? '21.5vw' : '7vw'} maxHeight="125px" position="relative" className={imageClass}>
        {isMobile ? <Image blurDataURL="/ocs/OCSMobileBanner_sm.png" placeholder="blur" className={imageClass} fill src="https://go.wallet.coinbase.com/static/OCSMobileBanner_sm.gif" alt="ocs banner" /> : <Image blurDataURL="/ocs/OCSDesktopBanner_sm3.png" placeholder="blur" className={imageClass} fill src="https://go.wallet.coinbase.com/static/OCSDesktopBanner_sm.gif" alt="ocs banner" />}
      </Box>
    </HStack>;
}

require("./OCSBanner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSBanner.tsx");