import { TabNavigation } from '@cbhq/cds-web/tabs/TabNavigation';
import { useActiveOCSTab } from ':dapp/pages/ocs/hooks/useActiveOCSTab';
import { useOCSTabs } from ':dapp/pages/ocs/hooks/useOCSTabs';
export const testID = 'ocs-tabs';
export function OCSTabs() {
  const tabs = useOCSTabs();
  const {
    activeTab,
    onTabChange
  } = useActiveOCSTab();
  const pinkOverride = "p1s73izl";
  return <div className={pinkOverride}>
      {tabs && tabs.length > 0 && <TabNavigation testID={testID} tabs={tabs} value={activeTab} onChange={onTabChange} />}
    </div>;
}

require("./OCSTabs.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSTabs.tsx");