import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { flattenObject } from 'cb-wallet-data/utils/flattenObject';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { usePreferredAccount, WalletPreferenceFeature } from ':dapp/hooks/usePreferredAccount';
import {
  ContentSurface,
  GetContentByTrendingUnauthRequest,
  GetContentUnauthResponse,
} from ':dapp/pages/ocs/types/ocsResponseTypes';

export function useOCSTrending(handleError?: (err?: Error) => void) {
  const { account } = usePreferredAccount({
    blockchainSymbol: 'ETH',
    feature: WalletPreferenceFeature.OCS,
  });

  return useQuery({
    queryKey: ['getContentByTrendingUnauth', account?.primaryAddressForDapp],
    queryFn: async () =>
      getContentByTrendingUnauth({
        surface: ContentSurface.SURFACE_OCS,
        walletAddress: account?.primaryAddressForDapp,
        pageOptions: {
          size: 9,
        },
      }),
    onError: /* istanbul ignore next */ function handleOnError(err?: Error) {
      if (handleError) {
        handleError(err);
      }
    },
    staleTime: 1000 * 40,
    suspense: false,
  });
}

export async function getContentByTrendingUnauth(params: GetContentByTrendingUnauthRequest) {
  return getJSON<GetContentUnauthResponse>('explore/getContentByTrendingUnauth', {
    surface: '1',
    ...flattenObject(params),
  });
}
