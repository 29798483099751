import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useIsFeatureEnabled } from 'cb-wallet-data/FeatureManager/hooks/useIsFeatureEnabled';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';

export const BASE_TEST_ID = 'ocs-tabs';

const messages = defineMessages({
  today: {
    defaultMessage: 'Today',
    description: 'Label for Today in the OCS page',
  },
  play: {
    defaultMessage: 'Play',
    description: 'Label for Play in Assets page',
  },
  progress: {
    defaultMessage: 'Progress',
    description: 'Label for Progress in the Assets page',
  },
  shop: {
    defaultMessage: 'Shop',
    description: 'Label for Shop in Assets page',
  },
});

export enum OCSTabKey {
  TODAY = 'today',
  PLAY = 'play',
  PROGRESS = 'progress',
  SHOP = 'shop',
}

export function useOCSTabs() {
  const { gamificationApiIssue, exploreApiIssue } = usePageOutageContext(); // this is controlled at runtime based on api success, etc
  const { formatMessage } = useIntl();

  const todayTabEnabled = useIsFeatureEnabled('ocs_today_tab');
  const playTabEnabled = useIsFeatureEnabled('ocs_play_tab');
  const progressTabEnabled = useIsFeatureEnabled('ocs_progress_tab');
  const shopTabEnabled = useIsFeatureEnabled('ocs_pages');

  return useMemo(() => {
    const visibleTabs: { id: OCSTabKey; label: string; testID: string }[] = [];

    if (gamificationApiIssue && exploreApiIssue) {
      return visibleTabs;
    }

    if (todayTabEnabled) {
      visibleTabs.push({
        id: OCSTabKey.TODAY,
        label: formatMessage(messages.today),
        testID: `${BASE_TEST_ID}-today`,
      });
    }

    if (playTabEnabled) {
      visibleTabs.push({
        id: OCSTabKey.PLAY,
        label: formatMessage(messages.play),
        testID: `${BASE_TEST_ID}-play`,
      });
    }

    if (progressTabEnabled && !gamificationApiIssue) {
      visibleTabs.push({
        id: OCSTabKey.PROGRESS,
        label: formatMessage(messages.progress),
        testID: `${BASE_TEST_ID}-progress`,
      });
    }

    if (shopTabEnabled) {
      visibleTabs.push({
        id: OCSTabKey.SHOP,
        label: formatMessage(messages.shop),
        testID: `${BASE_TEST_ID}-shop`,
      });
    }

    return visibleTabs;
  }, [
    gamificationApiIssue,
    exploreApiIssue,
    todayTabEnabled,
    playTabEnabled,
    progressTabEnabled,
    shopTabEnabled,
    formatMessage,
  ]);
}
