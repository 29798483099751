/* istanbul ignore file */

import { SpacingProps } from '@cbhq/cds-common';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, Group, HStack, VStack } from '@cbhq/cds-web/layout';
import { Tag } from '@cbhq/cds-web/tag/Tag';
import { TextBody, TextLabel1, TextTitle1 } from '@cbhq/cds-web/typography';

type TextHeaderProps = SpacingProps & {
  title: string;
  tag?: string;
  subtitle?: string;
  phoneContent?: React.ReactNode;
  id?: string;
};
type Props = SpacingProps &
  TextHeaderProps & {
    rightContent?: React.ReactNode;
    overrideMobile?: boolean;
    transpose?: boolean;
  };

/**
 * @param overrideMobile forces horizontal layout for rightContent on mobile width
 * @param transpose forces horizontal layout with rightContent and subtitle to be below (eg. earn badges for mobile)
 */
export function OCSSectionHeader({
  overrideMobile = false,
  transpose = false,
  rightContent,
  title,
  tag,
  subtitle,
  id,
  ...spacing
}: Props) {
  if (!rightContent) {
    <OCSSectionTextHeader {...spacing} title={title} tag={tag} subtitle={subtitle} id={id} />;
  }

  const { isPhone } = useBreakpoints();
  const phoneMode = isPhone && !overrideMobile;
  const flexDir = phoneMode || transpose ? ('column' as const) : ('row' as const);
  const alignDir = phoneMode ? ('flex-start' as const) : ('center' as const);

  // text in mobile mode is align center so easiest way to
  // line it up is just to pass it down
  const passThru = phoneMode ? rightContent : null;

  return (
    <Box
      flexDirection={flexDir}
      justifyContent="space-between"
      alignItems={alignDir}
      {...spacing}
      id={id}
    >
      {transpose ? (
        <Group gap={2} width="100%">
          <HStack justifyContent="space-between" width="100%">
            <OCSSectionTextHeader title={title} tag={tag} phoneContent={passThru} />
            {!phoneMode ? rightContent : null}
          </HStack>
          <OCSSectionTextHeader title="" subtitle={subtitle} phoneContent={passThru} />
        </Group>
      ) : (
        <>
          <OCSSectionTextHeader
            title={title}
            tag={tag}
            subtitle={subtitle}
            phoneContent={passThru}
          />
          {!phoneMode ? rightContent : null}
        </>
      )}
    </Box>
  );
}

export function OCSSectionTextHeader({
  title,
  tag,
  subtitle,
  phoneContent,
  id,
  ...spacing
}: TextHeaderProps) {
  if (!subtitle) {
    return (
      <HStack gap={2} alignItems="center">
        <TextTitle1 as="h2" {...spacing} id={id}>
          {title}
        </TextTitle1>
        {tag && (
          <Tag height={26} colorScheme="gray">
            <TextLabel1 as="span">{tag}</TextLabel1>
          </Tag>
        )}
      </HStack>
    );
  }

  return (
    <VStack {...spacing} justifyContent="center" id={id}>
      <HStack gap={2} alignItems="center">
        <TextTitle1 as="h2" spacingBottom={1} key="section_title_h2">
          {title}
        </TextTitle1>
        {tag && (
          <Tag height={26} colorScheme="gray">
            <TextLabel1 as="span">{tag}</TextLabel1>
          </Tag>
        )}
      </HStack>
      <TextBody as="p" color="foregroundMuted" spacingHorizontal={0}>
        {subtitle}
      </TextBody>
      {phoneContent}
    </VStack>
  );
}
