import { defineMessages, useIntl } from 'react-intl';
import Image from 'next/image';
import { Group, VStack } from '@cbhq/cds-web/layout';
import { TextBody, TextTitle3 } from '@cbhq/cds-web/typography';

import { OCSPageWrapper } from './OCSPageWrapper';

const messages = defineMessages({
  ocsOutageTitle: {
    defaultMessage: 'Oops! Onchain Summer is currently unavailble',
    description: 'OCS outage title',
  },
  ocsOutageSubtitle: {
    defaultMessage: 'We’re working on it. Please try again later.',
    description: 'OCS outage subtitle',
  },
});

export function OCSOutage() {
  const { formatMessage } = useIntl();

  return (
    <VStack width="100%" testID="ocs-outage">
      <OCSPageWrapper>
        <VStack alignItems="center" spacingVertical={4} gap={3}>
          <Image
            alt="Experiences not available"
            src="https://go.wallet.coinbase.com/static/chickenFishSystemError.png"
            width={240}
            height={240}
          />
          <Group gap={1} alignItems="center">
            <TextTitle3 as="div" align="center">
              {formatMessage(messages.ocsOutageTitle)}
            </TextTitle3>
            <TextBody as="p" color="foregroundMuted" spacingHorizontal={0}>
              {formatMessage(messages.ocsOutageSubtitle)}
            </TextBody>
          </Group>
        </VStack>
      </OCSPageWrapper>
    </VStack>
  );
}
