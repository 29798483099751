import { useCallback, useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { usePageOutageContext } from 'cb-wallet-data/stores/PageOutage/PageOutageProvider';
import { ResponsiveProps } from '@cbhq/cds-common';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { OCSHeroBanner } from ':dapp/pages/ocs/components/OCSHeroBanner';
import { useExperienceModalContext } from ':dapp/providers/ExperienceModalProvider';
import { useShareModalContext } from ':dapp/providers/ShareModalProvider';
import { useGetContentById } from '../hooks/useGetContentById';
import { useOCSFeatured } from '../hooks/useOCSFeatured';
import { useOCSOnchainExperiences } from '../hooks/useOCSOnchainExperiences';
import { OCSChallengeCard } from '../types/ocsResponseTypes';
const messages = defineMessages({
  heroBannerBadge: {
    defaultMessage: '💧 FRESH DROP',
    description: 'Badge for the hero banner in OCS'
  }
});
export const testID = 'ocs-header';
export const testIDLoading = 'ocs-header-loading';
const leftBorder = "l1mp53eu";
const rightBorder = "r1v5zsu8";
const responsiveHeaderSpacing: ResponsiveProps = {
  phone: {
    spacingHorizontal: 3,
    spacingTop: 2
  },
  tablet: {
    spacingHorizontal: 4,
    spacingTop: 2
  },
  desktop: {
    spacingHorizontal: 4,
    spacingTop: 2
  }
};
export function OCSHeader() {
  const {
    formatMessage
  } = useIntl();
  const {
    setExploreApiIssue,
    exploreApiIssue
  } = usePageOutageContext();
  const {
    data,
    isLoading
  } = useOCSFeatured(setExploreApiIssue);
  const {
    data: experiences,
    isLoading: isExperiencesLoading
  } = useOCSOnchainExperiences(undefined, setExploreApiIssue);
  const {
    openExperienceModal
  } = useExperienceModalContext();
  const searchParams = new URLSearchParams(window.location.search);
  const challengeIdParam = searchParams.get('challenge_id');
  const {
    openShareModal
  } = useShareModalContext();
  const {
    data: challengeById,
    isLoading: isLoadingChallengeById
  } = useGetContentById(challengeIdParam || '');
  const handleClick = useCallback((content: OCSChallengeCard) => () => {
    openExperienceModal(content);
  }, [openExperienceModal]);
  const handleShareClick = useCallback((content: OCSChallengeCard) => () => {
    openShareModal(content);
  }, [openShareModal]);
  const firstInCompletedChallenge: OCSChallengeCard | null = useMemo(() => {
    // alwyas return featured challenge if there is no trending challenges
    if (data && !experiences) {
      return {
        ...data.content.ocsChallengeCard,
        id: data.content.id
      };
    }

    // if there is no featured challenge then return the first incompleted challenge from trending challenges
    if (!data && experiences) {
      const incompleteChallenge = experiences.pages.flatMap(page => page.contentData).find(content => content.ocsChallengeCard.hasCompleted === 1);
      return incompleteChallenge ? {
        ...incompleteChallenge.ocsChallengeCard,
        id: incompleteChallenge.id
      } : null;
    }
    if (experiences && data) {
      const incompleteChallenge = experiences.pages.flatMap(page => page.contentData).find(content => content.ocsChallengeCard.hasCompleted === 1);
      const currentChallenge = {
        ...data.content.ocsChallengeCard,
        id: data.content.id
      };

      // we were unable to find an incompleted challenges from trending list so gonna return featured challenge even though it's completed
      if (!incompleteChallenge) {
        return currentChallenge;
      }

      // 0 means unknown and 1 means incomplete
      return data.content.ocsChallengeCard.hasCompleted <= 1 ? {
        ...data.content.ocsChallengeCard,
        id: data.content.id
      } : {
        ...incompleteChallenge.ocsChallengeCard,
        id: incompleteChallenge.id
      };
    }
    return null;
  }, [data, experiences]);
  const content: OCSChallengeCard | null = useMemo(() => {
    // this is an override to show  shared experience card from the url
    if (challengeById?.content.ocsChallengeCard) {
      return {
        ...challengeById.content.ocsChallengeCard,
        id: challengeById.content.id
      };
    }
    return firstInCompletedChallenge;
  }, [challengeById?.content.id, challengeById?.content.ocsChallengeCard, firstInCompletedChallenge]);
  const creator = useMemo(() => {
    return content ? {
      name: content.creatorName,
      iconUrl: content.creatorImageUrl
    } : undefined;
  }, [content]);
  const image = useMemo(() => ({
    src: content ? content.imageUrl : '/park-1.png'
  }), [content]);
  const action = useMemo(() => content ? {
    url: content.targetUrl,
    text: content.ctaText,
    points: content.points
  } : undefined, [content]);
  if (isLoading || isExperiencesLoading || isLoadingChallengeById && challengeIdParam) {
    return <OCSHeaderLoading />;
  }
  if (!content || exploreApiIssue) {
    return null;
  }
  return <HStack width="100%" justifyContent="space-between" alignItems="center" responsiveConfig={responsiveHeaderSpacing} testID={testID}>
      <OCSHeroBanner title={content.title} subTitle={content.shortDescription} image={image} creator={creator} action={action} hasCompleted={content.hasCompleted === 2} badge={formatMessage(messages.heroBannerBadge)} onClick={handleClick({
      ...content,
      id: content.id
    })} onShareClick={handleShareClick({
      ...content,
      id: content.id
    })} />
    </HStack>;
}
function OCSHeaderLoading() {
  return <Box width="100%" height={440} borderRadius="roundedXLarge" spacingHorizontal={4} testID={testIDLoading}>
      <HStack width="100%">
        <Box width="50%" background="backgroundAlternate" className={leftBorder} spacing={4}>
          <VStack width="100%" justifyContent="space-between">
            <VStack width="100%" gap={3}>
              <Box width={128} height={24} background="secondary" borderRadius="roundedXLarge" />
              <VStack width="100%" gap={1.5}>
                <HStack gap={1} alignItems="center">
                  <Box width={40} height={40} background="secondary" borderRadius="rounded" />
                  <Box width={80} height={28} background="secondary" />
                </HStack>
                <Box width="75%" height={56} background="secondary" />
                <Box width="100%" height={28} background="secondary" />
                <Box width="100%" height={28} background="secondary" />
              </VStack>
            </VStack>
            <Box width="100%" height={56} borderRadius="roundedXLarge" background="secondary" />
          </VStack>
        </Box>
        <Box width="50%" background="secondary" className={rightBorder} />
      </HStack>
    </Box>;
}

require("./OCSHeader.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSHeader.tsx");