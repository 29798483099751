import { PageOptions } from 'cb-wallet-data/stores/Presentation/types';

export enum ContentSurface {
  SURFACE_UNKNOWN = 0,
  SURFACE_OCS = 1,
  SURFACE_WALLET_EXPLORE = 2,
}

export enum ContentCategory {
  CATEGORY_UNKNOWN = 0,
  CATEGORY_OCS_GAMES = 1,
  CATEGORY_OCS_SOCIAL = 2,
  CATEGORY_OCS_CREATORS = 3,
  CATEGORY_OCS_FINANCE = 4,
  CATEGORY_OCS_MEDIA = 5,
  CATEGORY_OCS_OTHER = 8,
}

/**
 * The value in FEContentCategory enum should match ContentCategory which is
 * a backend version of categories and numbers will be used to send into the
 * api params. except "trending". trending will fetch all the categories.
 */
export enum FEContentCategory {
  TRENDING = 'trending',
  GAMES = 'games',
  SOCIAL = 'social',
  CREATORS = 'creator',
  FINANCE = 'finance',
  MEDIA = 'media',
}

export const labelToContentCategory: Record<FEContentCategory, ContentCategory | undefined> = {
  [FEContentCategory.TRENDING]: undefined,
  [FEContentCategory.GAMES]: ContentCategory.CATEGORY_OCS_GAMES,
  [FEContentCategory.MEDIA]: ContentCategory.CATEGORY_OCS_MEDIA,
  [FEContentCategory.SOCIAL]: ContentCategory.CATEGORY_OCS_SOCIAL,
  [FEContentCategory.FINANCE]: ContentCategory.CATEGORY_OCS_FINANCE,
  [FEContentCategory.CREATORS]: ContentCategory.CATEGORY_OCS_CREATORS,
};

enum ContentType {
  TYPE_UNKNOWN = 0,
  TYPE_OCS_CARD = 1,
}

export type OCSChallengeCard = {
  id: string;
  title: string;
  shortDescription: string;
  imageUrl: string;
  fullDescription: string;
  targetUrl: string;
  ctaText: string;
  // gamification fields
  functionSignature: string;
  contractAddress: string;
  tokenId: string;
  tokenAmount: string;
  // curation fields
  tier: number;
  points: number;
  featured: boolean;
  curated: boolean;
  creatorName: string;
  creatorImageUrl: string;
  numCompletions: number;
  hasCompleted: number;
};

type PresentationAnalytics = {
  surface: string;
  category: string;
  type: string;
  component_id: string;
  content_id: string;
  row_index: number;
  action_title: string;
  language: string;
  published_at: string;
  metadata: Record<string, string>;
};

type ContentComponentBase = {
  id: string;
  surface: ContentSurface;
  category: ContentCategory;
  type: ContentType;
  childComponents: ContentComponent;
  analytics: PresentationAnalytics;
  requiresEnrichment: boolean;
  startTs: string;
  expirationTs: string;
};

export type ContentComponent = ContentComponentBase & {
  ocsChallengeCard: OCSChallengeCard;
};

export type GetContentUnauthResponse = {
  pageDescription: {
    size: number;
    previousCursor: string;
    nextCursor: string;
  };
  contentData: ContentComponent[];
};

export type GetContentByTrendingUnauthRequest = {
  surface: ContentSurface;
  categories?: ContentCategory[];
  pageOptions?: PageOptions;
  walletAddress?: string;
};

export type GetFeaturedContentUnauthResponse = Omit<GetContentUnauthResponse, 'contentData'> & {
  content: ContentComponent;
};

export type UseOCSContentParams = {
  experience?: ContentCategory;
  pageOptions?: PaginationOptions;
};

type PaginationOptions = { size: number };
