import { useCallback } from 'react';
import { useQuery } from 'cb-wallet-data/hooks/useQuery';
import { getJSON } from 'cb-wallet-http/fetchJSON';

import { GetFeaturedContentUnauthResponse } from '../types/ocsResponseTypes';

export function useGetContentById(challengeId: string) {
  const fetchContentById = useCallback(async () => {
    return getJSON<GetFeaturedContentUnauthResponse>('explore/getContentByIdUnauth', {
      id: challengeId,
    });
  }, [challengeId]);

  return useQuery(['getContentById', challengeId], fetchContentById, { enabled: !!challengeId });
}
