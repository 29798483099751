import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CSSProperties } from '@linaria/core';
import Image from 'next/image';
import { PartialPaletteConfig, ResponsiveProps, useSpectrum } from '@cbhq/cds-common';
import { IconButton } from '@cbhq/cds-web/buttons';
import { ContentCard, ContentCardBody } from '@cbhq/cds-web/cards/ContentCard';
import { useBreakpoints } from '@cbhq/cds-web/hooks/useBreakpoints';
import { Box, HStack, VStack } from '@cbhq/cds-web/layout';
import { RemoteImage } from '@cbhq/cds-web/media/RemoteImage';
import { Pressable, ThemeProvider } from '@cbhq/cds-web/system';
import { TextBody, TextCaption, TextDisplay2, TextHeadline } from '@cbhq/cds-web/typography';
export const testID = 'ocs-hero-banner';
export const OCSBannerDummyData: OCSHeroBannerProps = {
  title: 'Blackbird Vacation Pass',
  subTitle: 'Mint your exclusive vacation pass that can take you to more than 50 restaurants worldwide',
  image: {
    src: '/hero-image.png'
  },
  badge: '💧 FRESH DROP',
  creator: {
    name: 'BLACKBIRD',
    iconUrl: '/black-bird.png'
  },
  action: {
    url: '/',
    text: 'Mint',
    points: 200
  }
};
type OCSHeroBannerProps = {
  title: string;
  subTitle?: string;
  image: {
    src: string;
    width?: number;
    height?: number;
  };
  badge?: string;
  creator?: {
    name: string;
    iconUrl: string;
  };
  action?: {
    text: string;
    url: string;
    points: number;
  };
  hasCompleted?: boolean;
  onClick?: () => void;
  onShareClick?: () => void;
};
const responsiveConfigForLayout: ResponsiveProps = {
  desktop: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  tablet: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  phone: {
    flexDirection: 'column-reverse',
    gap: 1
  }
};
const responsiveConfigForLeftSideWrapperLayout: ResponsiveProps = {
  desktop: {
    spacing: 4
  },
  tablet: {
    spacing: 3
  },
  phone: {
    spacing: 2,
    gap: 2
  }
};
const imageWrapperResponsiveConfig: ResponsiveProps = {
  phone: {
    spacing: 2
  }
};
const buttonClass = "bed5g0r";
const darkContentCardBodyClass = "d1h782wn";
const messages = defineMessages({
  alt: {
    defaultMessage: 'Image for {name} OCS',
    description: 'OCS creator image alt text'
  },
  ends: {
    defaultMessage: 'ENDS',
    description: 'OCS ends alt text'
  },
  timer: {
    defaultMessage: '{timer}',
    description: 'OCS timer alt text'
  },
  points: {
    defaultMessage: 'points',
    description: 'indication after a number to show that the number represents points for on chain summer'
  },
  ctaPoints: {
    defaultMessage: ` +{points} points`,
    description: 'indication of number of points to be gained from completing the featured challenge'
  },
  challengeCompleted: {
    defaultMessage: 'Completed',
    description: 'indicator that the user has already completed this challenge'
  }
});
export function OCSHeroBanner({
  title,
  subTitle,
  image,
  badge,
  action,
  creator,
  hasCompleted,
  onClick,
  onShareClick
}: OCSHeroBannerProps) {
  const {
    formatMessage
  } = useIntl();
  const spectrum = useSpectrum();
  const paletteOverride: PartialPaletteConfig = useMemo(() => ({
    secondary: spectrum === 'dark' ? 'gray100' : 'gray0',
    secondaryForeground: spectrum === 'dark' ? 'gray0' : 'gray100'
  }), [spectrum]);
  const {
    isPhone,
    isDesktop
  } = useBreakpoints();
  const imageClass = useMemo(() => {
    if (isPhone) {
      return "i1vl6zxd";
    }
    return "i8vsv18";
  }, [isPhone]);
  const cardStyle = useMemo(() => {
    const style: CSSProperties = {
      padding: 0,
      backgroundColor: 'rgba(30, 32, 37, 1)'
    };
    return style;
  }, []);
  const points = action?.points ? formatMessage(messages.ctaPoints, {
    points: action.points
  }) : '';
  return <ContentCard testID={testID} borderRadius="roundedXLarge" overflow="hidden" style={cardStyle} gap={!isPhone ? 2 : 0} maxWidth="unset" width="100%">
      <ContentCardBody gap={10} background="secondary" className={spectrum === 'dark' ? darkContentCardBodyClass : undefined} width="100%" body={<Box testID={`${testID}--layout`} responsiveConfig={responsiveConfigForLayout}>
            <VStack width="100%" justifyContent="space-between" alignItems="stretch" responsiveConfig={responsiveConfigForLeftSideWrapperLayout}>
              <VStack width="100%" height="100%" gap={2}>
                {badge && <Box spacingBottom={1}>
                    <Box dangerouslySetBackground="#FF8DCF" borderRadius="roundedXLarge" spacingVertical={0.5} spacingHorizontal={1.5}>
                      <TextCaption as="span" dangerouslySetColor="var(--gray0)" mono>
                        {badge}
                      </TextCaption>
                    </Box>
                  </Box>}
                {creator && <HStack gap={1} alignItems="center">
                    <Box alignItems="center" borderRadius="rounded" overflow="hidden">
                      <RemoteImage alt={formatMessage(messages.alt, {
                name: creator.name
              })} source={creator.iconUrl} resizeMode="cover" height={40} width={40} />
                    </Box>
                    <TextCaption as="p" testID={`${testID}--creator-title`}>
                      {creator.name}
                    </TextCaption>
                  </HStack>}

                <Box maxWidth="400px">
                  <TextDisplay2 as="p" testID={`${testID}--title`}>
                    {title}
                  </TextDisplay2>
                </Box>
                {subTitle && <TextBody as="p" spacingBottom={5} color="foregroundMuted">
                    {subTitle}
                  </TextBody>}
              </VStack>
              {action ? <ThemeProvider spectrum={spectrum} palette={paletteOverride}>
                  <Box>
                    <Pressable background="foreground" className={buttonClass} testID="ocs-hero-banner-button" onPress={onClick} block>
                      {hasCompleted ? <TextHeadline as="span" color="primaryForeground">
                          {formatMessage(messages.challengeCompleted)}
                        </TextHeadline> : <>
                          <TextHeadline as="span" color="primaryForeground">
                            {action.text}
                          </TextHeadline>
                          <TextHeadline as="span" color="primaryForeground">
                            {points}
                          </TextHeadline>
                        </>}
                    </Pressable>
                  </Box>
                </ThemeProvider> : null}
            </VStack>
            <Box position="relative" justifyContent="flex-end" width="100%" minWidth={isDesktop ? 550 : undefined} responsiveConfig={imageWrapperResponsiveConfig}>
              <Image alt={title} src={image.src} className={imageClass} fill={!isPhone} width={isPhone ? 500 : undefined} height={isPhone ? 212 : undefined} />
              <Box position="absolute" top={12} right={16} zIndex={1}>
                <ThemeProvider scale="small">
                  <IconButton compact name="share" testID="ocs-card-share" onClick={onShareClick} />
                </ThemeProvider>
              </Box>
            </Box>
          </Box>} />
    </ContentCard>;
}

require("./OCSHeroBanner.linaria.module.css!=!../../../../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./OCSHeroBanner.tsx");