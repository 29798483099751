// Note: these eslint disables are required for the circular references needed by these types
// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/consistent-type-definitions
interface FlattenableValueArray extends Array<FlattenableValue> {}
type FlattenableValue =
  | string
  | number
  | boolean
  | null
  | undefined
  | FlattenableObject
  | FlattenableValueArray;
// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style, @typescript-eslint/consistent-type-definitions
interface FlattenableObject {
  [key: string]: FlattenableValue;
}

/**
 * Flattens a nested object into a single-level object with dot-separated keys.
 * This is particularly useful for preparing data to be passed to URLSearchParams
 * or when using getJSON from cb-wallet-http/fetchJSON.
 *
 * @param obj - The object to flatten
 * @returns A Record<string,string> representing the flattened object.
 *
 * @example
 * const nestedObject = {
 *   user: {
 *     name: 'Alice',
 *     address: {
 *       city: 'Wonderland',
 *       postalCode: 12345
 *     }
 *   },
 *   preferences: {
 *     theme: 'dark'
 *   }
 * };
 * const flattened = flattenObject(nestedObject);
 * // Output: {
 * //   'user.name': 'Alice',
 * //   'user.address.city': 'Wonderland',
 * //   'user.address.postalCode': '12345',
 * //   'preferences.theme': 'dark'
 * // }
 */
export function flattenObject(obj: FlattenableObject): Record<string, string> {
  const result: Record<string, string> = {};

  function flatten(_obj: FlattenableObject, prefix = ''): void {
    if (_obj === null || _obj === undefined) {
      return;
    }

    for (const key in _obj) {
      if (Object.prototype.hasOwnProperty.call(_obj, key)) {
        const value = _obj[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (value === undefined) {
          continue;
        }

        if (Array.isArray(value)) {
          value.forEach(function appendArrayItem(item, index) {
            const arrayKey = `${prefixedKey}[${index}]`;
            if (typeof item === 'object' && item !== null && !Array.isArray(item)) {
              Object.assign(result, flatten(item, arrayKey));
            } else if (Array.isArray(item)) {
              flatten({ [arrayKey]: item });
            } else {
              result[`${prefixedKey}[${index}]`] = String(item);
            }
          });
        } else if (typeof value === 'object' && value !== null) {
          Object.assign(result, flatten(value, prefixedKey));
        } else {
          result[prefixedKey] = String(value);
        }
      }
    }
  }

  flatten(obj);
  return result;
}
